import { BenefitScreen } from "./benefit-screen/benefit-screen"
import * as css from "./benefits-list.moduls.scss"
import * as React from "react"
import { BenefitsInfoList } from "./benefits-info-list"

export const BenefitsList = () => {
  return (
    <div className={css.list}>
      {BenefitsInfoList.map((benefit, index) => (
        <BenefitScreen
          key={index}
          benefit={benefit}
          reverse={index % 2 !== 0}
        />
      ))}
    </div>
  )
}
