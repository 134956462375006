import { Benefit } from "./benefit"

export const BenefitsInfoList: Benefit[] = [
  {
    title: "benefit_1_t",
    image: "/benefits/team.png",
    children: [
      {
        title: "benefit_1_feature_1_t",
        description: "benefit_1_feature_1_d",
      },
      {
        title: "benefit_1_feature_2_t",
        description: "benefit_1_feature_2_d",
      },
      {
        title: "benefit_1_feature_3_t",
        description: "benefit_1_feature_3_d",
      },
      {
        title: "benefit_1_feature_4_t",
        description: "benefit_1_feature_4_d",
      },
    ],
  },
  {
    title: "benefit_2_t",
    image: "/benefits/development.png",
    children: [
      {
        title: "benefit_2_feature_1_t",
        description: "benefit_2_feature_1_d",
      },
      {
        title: "benefit_2_feature_2_t",
        description: "benefit_2_feature_2_d",
      },
      {
        title: "benefit_2_feature_3_t",
        description: "benefit_2_feature_3_d",
      },
      {
        uaOnly: true,
        title: "benefit_2_feature_4_t",
        description: "benefit_2_feature_4_d",
      },
      {
        title: "benefit_2_feature_5_t",
        description: "benefit_2_feature_5_d",
      },
    ],
  },
  {
    title: "benefit_3_t",
    image: "/benefits/health.png",
    children: [
      {
        title: "benefit_3_feature_1_t",
        description: "benefit_3_feature_1_d",
      },
      {
        title: "benefit_3_feature_2_t",
        description: "benefit_3_feature_2_d",
      },
      {
        title: "benefit_3_feature_3_t",
        description: "benefit_3_feature_3_d",
      },
    ],
  },
  {
    title: "benefit_4_t",
    image: "/benefits/office.png",
    children: [
      {
        title: "benefit_4_feature_1_t",
        description: "benefit_4_feature_1_d",
      },
      {
        title: "benefit_4_feature_2_t",
        description: "benefit_4_feature_2_d",
      },
      {
        title: "benefit_4_feature_3_t",
        description: "benefit_4_feature_3_d",
      },
      {
        title: "benefit_4_feature_4_t",
        description: "benefit_4_feature_4_d",
      },
    ],
  },
  {
    title: "benefit_5_t",
    image: "/benefits/balance.png",
    children: [
      {
        title: "benefit_5_feature_1_t",
        description: "benefit_5_feature_1_d",
      },
      {
        title: "benefit_5_feature_2_t",
        description: "benefit_5_feature_2_d",
      },
      {
        title: "benefit_5_feature_3_t",
        description: "benefit_5_feature_3_d",
      },
      {
        title: "benefit_5_feature_4_t",
        description: "benefit_5_feature_4_d",
      },
      {
        title: "benefit_5_feature_5_t",
        description: "benefit_5_feature_5_d",
      },
    ],
  },
]
