import "./../../style/common.scss"
import * as React from "react"
import { CommonLayout } from "../../common/common-layout/common-layout"
import { BenefitsPage } from "../../page-components/career/benefits/benefits-page"

export default function Benefits(props) {
  return (
    <CommonLayout {...props}>
      <BenefitsPage/>
    </CommonLayout>
  )
}
