import { CareerNav } from "../career-nav/career-nav"
import { BenefitsList } from "./benefits-list"
import * as React from "react"
import { useTranslation } from "../../../common/use-translations/use-translations"
import { Helmet } from "react-helmet"

export function BenefitsPage() {
  const _ = useTranslation()
  return (
    <>
      <Helmet>
        <title>{_.benefits} - OBRIO</title>
      </Helmet>
      <CareerNav />
      <BenefitsList />
    </>
  )
}