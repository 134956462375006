import * as css from "./benefit-screen.module.scss"
import classNames from "classnames"
import * as React from "react"
import { Benefit } from "../benefit"
import { useTranslation } from "../../../../common/use-translations/use-translations"
import { useLocale } from "../../../../common/use-locale/use-locale"

export const BenefitScreen = (props: {
  benefit: Benefit
  reverse: boolean
}) => {
  const _ = useTranslation()
  const lang = useLocale().locale
  const reverseClass = props.reverse ? css.reverse : ""
  return (
    <section className={css.section}>
      <div
        className={classNames("container", "overflow-visible", css.container)}
      >
        <div className={classNames(css.grid, reverseClass)}>
          <div className={css.text}>
            <h2 className={css.title}>{_[props.benefit.title]}</h2>
            <ul className={css.list}>
              {props.benefit.children.map((child, index) => {
                if (lang === "uk" || !child.uaOnly) {
                  return (
                    <li key={index}>
                      <div className={css.listTitle}>{_[child.title]}</div>
                      <p className={css.listText}>{_[child.description]}</p>
                    </li>
                  )
                }
              })}
            </ul>
          </div>

          <div className={classNames(css.image, reverseClass)}>
            <img src={props.benefit.image} alt="" />
          </div>
        </div>
      </div>
    </section>
  )
}
