// extracted by mini-css-extract-plugin
export var section = "benefit-screen-module--section--VWfeC";
export var container = "benefit-screen-module--container--qJHia";
export var grid = "benefit-screen-module--grid--hiQL6";
export var reverse = "benefit-screen-module--reverse--TkSI0";
export var text = "benefit-screen-module--text--mxunm";
export var title = "benefit-screen-module--title--RQ30l";
export var list = "benefit-screen-module--list--rswP8";
export var listTitle = "benefit-screen-module--list-title--U643d";
export var listText = "benefit-screen-module--list-text--2R7ki";
export var image = "benefit-screen-module--image--buegU";